.preloader img {
  padding: 10px;
  width: 50px;
  height: 50px;
  background-size: contain;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.small-text {
  font-size: 0.7rem !important; /* Using !important ensures your style takes precedence */
}
